import { createChart,CrosshairMode } from 'lightweight-charts';
import { mglobals } from './BinanceSocket';

export function createCandleStick(div) {
    const chart = createChart(div, { width: 700, height: 300, 
        crosshair: {
	    	mode: CrosshairMode.Normal,
    	} 
        });
    mglobals.chart = chart;
    const candlesChart = chart.addCandlestickSeries(
        {
            upColor: 'rgb(38,166,154)',
            downColor: 'rgb(255,82,82)',
            wickUpColor: 'rgb(38,166,154)',
            wickDownColor: 'rgb(255,82,82)',
            borderVisible: false,
        }
    );
    //lineSeries.setData([
    //{ time: '2019-04-11', value: 80.01 },
    return candlesChart;

} 