
import React, { Component } from 'react';
import { mglobals } from '../Components/BinanceSocket';
import { createCandleStick } from '../Components/Charts';

class LChart extends Component {
    state = {  } 
    
    componentDidMount() {
        var div = document.getElementById('candleChart');
        if(div.childNodes.length ==0) {
            var candleChart =  createCandleStick(document.getElementById('candleChart'));
            mglobals.wschart = candleChart;
        }
    }

    render() { 
        return (<>
            <div id='candleChart'/>
        </>);
    }
}
 
export default LChart;
