import React, { Component } from 'react';
import {Row,Col,Button} from 'react-bootstrap'; 

import {createSocket,addSubscription} from '../Components/BinanceSocket' 

import './btc.css'
import LChart from './LChart';

class BTC extends Component {
    state = {


    } 
    dataref = React.createRef();

    scrollToBottom = () => {
        let dataDiv = this.dataref.current;
       
      }

    componentDidMount() {
        var btcDiv = document.getElementById("btc");
        addSubscription(btcDiv,this.scrollToBottom);
        
    }

    render() { 
        return (<div className='container'>
             <br/>
            <Row>
                <Col className="col-9 chart"><LChart/></Col>
                <Col className="col-3">
                    <h6>BTC USD 1min CandleStick</h6>    
                    <div id="btc" 
                    ref={this.dataref} className='dataDiv'/></Col>
                
            </Row>
        </div>);
    }
}
 
export default BTC;