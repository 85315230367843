import logo from './logo.svg';
import './App.css';
import BTC from './Views/BTC';
import { createSocket } from './Components/BinanceSocket';
import Menu from './Components/Menu'

import {
  BrowserRouter as Router,
  Routes, Route

} from 'react-router-dom';

function App() {
  createSocket();
       
  return (
    <div className="App">
      <Router>
      <div className="row heading">
          <div className="col"> <h3> <img style={{width:"40px"}} 
              src="https://www.edbetter.uk/wp-content/uploads/2020/02/images.jpg"/> 
              <a href="https://www.edbetter.uk/"><sub>EdBetter</sub></a>    </h3>
              <span className="tagline">Trading</span>
              </div>
              <div className="centerMenu"> <Menu/> </div>
          </div>
          <BTC/>
     </Router>
    </div>
  );
}

export default App;
