export const mglobals = {
    wsData : []    
};

export function createSocket() {

    var {wssocket} = mglobals;  
    let socket
    if(!wssocket){
        socket = new WebSocket("wss://stream.binance.com:9443/ws/btcusdt@kline_1m" );
        mglobals.wssocket = socket;
    }else{
        return mglobals.wssocket;
    }    
    return socket;
}  

export function addTick(x) {
    var d = document.createElement( 'div' );
    d.innerHTML = x;

    return d;
}

export function cDate(E) {
   var s = new Date(E)
   var d = {
    year : s.getFullYear(),
    month : s.getMonth() + 1,
    day : s.getDay(),
    hour: s.getHours(),
    min: s.getMinutes(),
    sec : s.getSeconds()
  }
  return d; 
}

export function strDate(E) {
    var s = new Date(E)
    var d = {
     year : s.getFullYear(),
     month : s.getMonth() + 1,
     day : s.getDay(),
     hour: s.getHours(),
     min: s.getMinutes(),
     sec : s.getSeconds()
   }
   var vals = Object.values(d);
   
   return d.year + "-" + d.month + "-" + d.day + " " + d.hour +":" + d.min; 
 }

var currentBar = {
    open: null,
    high: null,
    low: null,
    close: null,
    time: null,
};




export function transform(data,el,cb){
    var {wschart} = mglobals;  
    var {E,k} = data; 
    var {o,h,l,c} = k;
    //var sDate = cDate(E);
    currentBar.open = o;
    currentBar.high = h;
    currentBar.low = l;
    currentBar.close = c;
    currentBar.time = E/1000;
    wschart.update(currentBar);
    var pDate = strDate(E);
    
    var y = addTick(Array(pDate,o,h,l,c).join("|"))
    el.append(y);
    cb();
}

export function addData(data){
    var {E,k} = data; 
    var {o,h,l,c} = k;
   // var sDate = cDate(E);
    
    var bar = {
        open: null,
        high: null,
        low: null,
        close: null,
        time: null,
    };

    bar.open = o;
    bar.high = h;
    bar.low = l;
    bar.close = c;
    bar.time = E/1000;
    mglobals.wsData.push(bar);
    // if(mglobals.wsData.length == 50) {
    //     initChart();
    // }

}

export function initChart(){
    var {wsData,wschart} = mglobals;  
    var data = wsData;
    wschart.setData(data);
    mglobals.chart.timeScale().fitContent();
    // var datesForMarkers = [data[data.length - 39], data[data.length - 19]];
    // var indexOfMinPrice = datesForMarkers.reduce((index,e,i,array) => {
    //         e.high < array[i].high ? i: index,0 
    // });
   


    // for (var i = 1; i < datesForMarkers.length; i++) {
    //     if (datesForMarkers[i].high < datesForMarkers[indexOfMinPrice].high) {
    //         indexOfMinPrice = i;
    //     }
    // }
}


export function addSubscription(div,callback) {
    var {wssocket} = mglobals;  
    wssocket.onmessage = (e) => {
        var btgnData = JSON.parse(e.data);
        addData(btgnData);
        transform(btgnData,div,callback);
    }
}  

